/**
 * 
 * @param {Function} fn 
 * @param {interval} interval 
 */
function throttle(fn, interval) {
    var lastCall, timeoutId;

    return function () {
        var now = new Date().getTime();
        if (lastCall && now < (lastCall + interval)) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(function () {
                lastCall = now;
                fn.call();
            }, interval - (now - lastCall));
        } else {
            lastCall = now;
            fn.call();
        }
    };
}

/**
 * Highlight nav menu items on scroll.
 * 
 * The container with the scroll should have the following attributes:
 * <div class="js-highlight-nav" data-target="navbar" ...> ... </div>
 * 
 * It should also have the CSS attribute "position: relative;" or "position: absolute;"
 * 
 * The value of the attribute "data-target" is the ID of the nav bar containing the links to the
 * different sections of the scrollable container. When a linked section is in view, the corresponding link
 * and it's parent will get the class "active".
 * 
 * The scrollable container should have "overflow-y: auto" and a height, to be scrollable.
 * 
 * The sections inside the scrollable container should have an ID which can be scrolled to from the nav bar.
 * They should also have the class "js-section".
 */
(function (win) {
    var highlighters = document.getElementsByClassName('js-highlight-nav');

    if (!highlighters.length) {
        return false;
    }

    for (var highlighterIndex = 0; highlighterIndex < highlighters.length; highlighterIndex++) {
        var highlighter = highlighters[highlighterIndex];

        if (!highlighter.hasAttribute('data-target')) {
            continue;
        }

        var target = document.getElementById(highlighter.getAttribute('data-target'));

        if (!target) {
            continue;
        }

        /**
         * Get all sections.
         */
        var sectionCollection = highlighter.getElementsByClassName('js-section'),
            sections = [],
            sectionIdToNavigationLinks = {};

        /**
         * Get sections in reverse order. It is in reverse order so we can abort the iteration as soon
         * as we find a match.
         */
        for (var sectionIndex = sectionCollection.length - 1; sectionIndex >= 0; sectionIndex--) {
            if (sectionCollection[sectionIndex].hasAttribute('id')) {
                sections.push(sectionCollection[sectionIndex]);

                /**
                 * Also save nav links for each section.
                 */
                var id = sectionCollection[sectionIndex].getAttribute('id'),
                    navLinks = target.querySelectorAll('a[href="#' + id + '"]');

                if (navLinks.length) {
                    sectionIdToNavigationLinks[id] = navLinks[0];
                }
            }
        }

        function highlightNavigation() {
            /**
             * Get scroll top for the scrollable container.
             */
            var scrollTop = highlighter.scrollTop ? highlighter.scrollTop : 0;

            for (var sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
                var section = sections[sectionIndex];

                /**
                 * Check the distance from each section to the top of the scrollable container.
                 */
                var sectionTop = section.offsetTop ? section.offsetTop : 0;

                /**
                 * If the section is in view.
                 */
                if (scrollTop >= sectionTop) {
                    var id = section.getAttribute('id'),
                        navLink = sectionIdToNavigationLinks[id];

                    /**
                     * Check if a change is needed.
                     */
                    if (!navLink.classList.contains('active')) {
                        /**
                         * Remove the class "active" from all nav links.
                         */
                        for (id in sectionIdToNavigationLinks) {
                            if (sectionIdToNavigationLinks.hasOwnProperty(id)) {
                                sectionIdToNavigationLinks[id].classList.remove('active');
                                sectionIdToNavigationLinks[id].parentElement.classList.remove('active');
                            }
                        }

                        /**
                         * Add the class "active" to the current nav link.
                         */
                        navLink.classList.add('active');
                        navLink.parentElement.classList.add('active');
                        navLink.scrollIntoView({behavior: "smooth", block: "end"});
                    }

                    /**
                     * Abort the loop since the correct section was found.
                     */
                    return false;
                }
            }
        }

        /**
         * Run the highlighter on the scroll event, but throttled.
         */
        highlighter.addEventListener('scroll', throttle(highlightNavigation, 100));

        /**
         * Also run the highlighter on resize if another section should happen to scroll into view.
         */
        win.addEventListener('resize', throttle(highlightNavigation, 100));

        /**
         * Highlight on load.
         */
        highlightNavigation();
    }
})(window);